
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "organisation-and-facility-warehouse-detail",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
    };
  },
});
